<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Storni"
    :route-father="routeFather"
  >
    <template v-slot:header-actions>
      <v-btn text link @click="goToNew">
        <v-icon class="mr-2">mdi-plus</v-icon> Nuovo
      </v-btn>
    </template>
    <template v-slot:toolbar-extension>
      <div class="d-flex justify-center align-center" style="width: 100%">
        <SearchBar
          class="ml-2 mb-2"
          placeholder="Cerca storni ..."
          :search-icon="null"
          expand-on-focus
        ></SearchBar>
      </div>
    </template>
    <template v-slot:content>
      <div style="height: 100%; overflow-y: hidden" ref="dataTableWrapper">
        <TypeDataTable
          v-model="selected"
          loading-text="Caricamento storni ..."
          :headers="headers"
          :loading="loading"
          :items="filteredPaymentDebits"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :height="dataTableHeight"
          @edit="handleEdit"
          @delete="handleDelete"
          @dblclick:row="handleEditDoubleClick"
        >
          <template v-slot:custom-amount="{item}">
            <span v-if="!!item.amount">
              € {{item.amount}}
            </span>
            <span v-else>
              Non specificato
            </span>
          </template>
          <template v-slot:custom-customer="{item}">
            <span v-if="item.type == 'bill' && !!item.bills && item.bills.length > 0 && !!item.bills[0].billCustomers && item.bills[0].billCustomers.length > 0 && !!item.bills[0].billCustomers[0].customer">
              {{item.bills[0].billCustomers[0].customer.firstname}} {{item.bills[0].billCustomers[0].customer.lastname}}
            </span>
            <span v-else-if="item.type == 'customer' && !!item.customer">
              {{item.customer.firstname}} {{item.customer.lastname}}
            </span>
          </template>
          <template v-slot:custom-status="{item}">
            <v-chip 
              v-if="item.status == 'beginning'" 
              color="error" 
              label
            >
              Non pagato
            </v-chip>
            <span 
              v-else-if="item.status == 'inProgress'"
              color="warning" 
              label
            >
              Pagato parzialmente
            </span>
            <v-chip
              v-else-if="item.status == 'finished'"
              color="success" 
              label
            >
              Pagato totalmente
            </v-chip>
          </template>
        </TypeDataTable>
      </div>
    </template>
  </FullScreenDialog >
</template>

<script>
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'
import StandardMenu from '@/components/common/StandardMenu.vue'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import { SearchBar } from "likablehair-ui-components";
import paymentDebitsService from '@/services/paymentDebits/paymentDebits.service.js'

export default {
  name: "PaymentDebitsList",
  components: {
    FullScreenDialog,
    SearchBar,
    TypeDataTable,
    StandardMenu
  },
  data: function() {
    let props = this.$router.resolve({name: 'Payments'});

    return {
      dialog: true,
      loadingItems: false,
      loadingHeaders: false,
      routeFather: props.href,
      selected: undefined,
      dataTableHeightValue: 400,
      paymentDebits: [],
      headers: [],
      page: 1,
      rowPerPage: 20,
      totalPages: 0,
      resizeListener: undefined
    }
  },
  mounted: function() {
    this.fetchHeaders()
    this.fetchPaymentDebits()

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    this.resizeListener = function() {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    }
    this.resizeListener = this.resizeListener.bind(this)
    window.addEventListener('resize', this.resizeListener)
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    fetchPaymentDebits() {
      this.loadingItems = true
      paymentDebitsService.list(this.page, this.rowPerPage, {
        types: ['bill', 'customer']
      }).then((results) => {
        if(this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage
        }

        if(this.page != results.page) {
          this.page = results.page
        }

        this.totalPages = results.totalPages
        if(this.totalPages < this.page && this.totalPages != 0) {
          this.page = this.totalPages
        }

        this.paymentDebits = results.rows
        this.loadingItems = false
      })
    },
    fetchHeaders() {
      this.loadingHeaders = true
      paymentDebitsService.fields().then((headers) => {
        this.headers = headers
        this.loadingHeaders = false
      })
    },
    handleEdit(paymentDebit) {
      this.$router.push({
        name: 'PaymentDebitsEditForm',
        params: {
          id: paymentDebit.id
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'PaymentDebitsList'}).href
        }
      })
    },
    handleEditDoubleClick(row, {item: paymentDebit}) {
      this.$router.push({
        name: 'PaymentDebitsEditForm',
        params: {
          id: paymentDebit.id
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'PaymentDebitsList'}).href
        }
      })
    },
    handleDelete(paymentDebit) {
      let confirmed = confirm('Sei sicuro di voler eliminare la spesa?')

      if(!!confirmed) {
        paymentDebitsService.delete(paymentDebit).then(() => {
          this.fetchPaymentDebits()
        })
      }
    },
    goToNew() {
      this.$router.push({
        name: 'PaymentDebitsNewForm',
        query: {
          pathToGoBack: this.$router.resolve({name: 'PaymentDebitsList'}).href
        }
      })
    }
  },
  computed: {
    filteredPaymentDebits() {
      return this.paymentDebits
    },
    loading() {
      return this.loadingItems || this.loadingHeaders
    },
    dataTableHeight() {
      return this.dataTableHeightValue + 'px'
    }
  },
  watch: {
    page() {
      this.fetchPaymentDebits()
    },
    rowPerPage() {
      this.fetchPaymentDebits()
    },
  }
}
</script>